var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"icons-page mt-3",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h5',[_c('v-icon',[_vm._v("mdi-format-list-bulleted")]),_vm._v(" รายการบริหารโครงการ ")],1)]),_c('v-col',{staticClass:"d-md-flex justify-md-end px-0",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"set-font-kanit rounded-lg elevation-4",attrs:{"color":"primary","outlined":"","large":""},on:{"click":function($event){return _vm.$router.push('/fromservicemange')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-plus")]),_vm._v(" เพิ่มโครงการ")],1)],1)],1),_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"elevation-3 rounded-lg",attrs:{"append-icon":"mdi-magnify","label":"ค้นหาข้อมูล","background-color":"#ffffff","placeholder":"ชื่อโครงการ, ตำบล, อำเภอ, จังหวัด เป็นต้น","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"material-table set-shadow",attrs:{"disable-sort":true,"headers":_vm.headers,"items":_vm.projectList,"search":_vm.search,"loading":_vm.loading,"loading-text":"กำลังโหลดข้อมูล...","no-data-text":"ไม่พบข้อมูล !","no-results-text":"ไม่พบข้อมูล !","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.project_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.project_profress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{staticClass:"elevation-3 rounded-lg",attrs:{"color":"warning","height":"25"},model:{value:(item.project_profress),callback:function ($$v) {_vm.$set(item, "project_profress", $$v)},expression:"item.project_profress"}},[_vm._v(" "+_vm._s(item.project_profress)+" % ")])]}},{key:"item.project_budget",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.project_budget))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.detail(item.project_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"set-font-kanit"},[_vm._v("รายละเอียด")])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.confirmDelete(item.project_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("ลบ")])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialogConfirmDelete),callback:function ($$v) {_vm.dialogConfirmDelete=$$v},expression:"dialogConfirmDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"set-font-kanit"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete-outline")]),_vm._v(" ยืนยันการลบโครงการ ! ")],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-lg button-shadow elevation-3 set-font-kanit",attrs:{"large":"","color":"primary"},on:{"click":_vm.deleteProject}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-save")]),_vm._v(" ยืนยัน ")],1),_c('v-btn',{staticClass:"rounded-lg button-shadow elevation-3 set-font-kanit",attrs:{"large":"","color":"error","outlined":""},on:{"click":function($event){_vm.dialogConfirmDelete = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-times")]),_vm._v(" ยกเลิก ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }